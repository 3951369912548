import { faFlushed as FlushedRegular } from "@fortawesome/free-regular-svg-icons"
import { faFlushed as FlushedSolid } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/controls/button"
import RemoteImage from "components/utils/remoteImage"
import GridLayout from "layouts/gridLayout"
import React from "react"

//
// Visualises the button states
//
const Buttons = (props) => {
  return (
    <>
      <GridLayout type="fixed">
        <p
          className="
            col-span-12 
            mt-10
            font-bold text-3xl
            text-grey-darker"
        >
          Buttons
        </p>

        {/* Default */}
        <p
          className="
            col-span-12 
            mt-10 mb-5
            font-bold text-xl
            text-grey-darker"
        >
          Default
        </p>

        <Button
          text="Naked"
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Primary"
          type="primary"
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Secondary"
          type="secondary"
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        {/* Keyline */}
        <p
          className="
          col-span-12 
          mt-10 mb-5
          font-bold text-xl
          text-grey-darker
          "
        >
          Keyline
        </p>

        <Button
          text="Naked"
          keyline={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Primary"
          type="primary"
          keyline={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Secondary"
          type="secondary"
          keyline={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        {/* Disabled */}
        <p
          className="
          col-span-12 
          mt-10 mb-5
          font-bold text-xl
          text-grey-darker
          "
        >
          Disabled
        </p>

        <Button
          text="Naked"
          disabled={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Primary"
          type="primary"
          disabled={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Secondary"
          type="secondary"
          disabled={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        {/* Loading */}
        <p
          className="
          col-span-12 
          mt-10 mb-5
          font-bold text-xl
          text-grey-darker
          "
        >
          Loading
        </p>

        <Button
          text="Naked"
          loading={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Primary"
          type="primary"
          loading={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Secondary"
          type="secondary"
          loading={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        {/* Flat */}
        <p
          className="
          col-span-12 
          mt-10 mb-5
          font-bold text-xl
          text-grey-darker
          "
        >
          Flat
        </p>

        <Button
          text="Naked"
          flat={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Primary"
          type="primary"
          flat={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Secondary"
          type="secondary"
          flat={true}
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        {/* Sizes */}
        <p
          className="
          col-span-12 
          mt-10 mb-5
          font-bold text-xl
          text-grey-darker
          "
        >
          Sizes
        </p>

        <Button
          text="Small"
          size="sm"
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Medium"
          size="md"
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        <Button
          text="Large"
          size="lg"
          className="col-span-12 mb-4 tablet:col-span-4 desktop:col-span-3"
          onClick={() => {
            console.log("Click!")
          }}
        />

        {/* Button with content */}
        <p
          className="
          col-span-12 
          mt-10 mb-5
          font-bold text-xl
          text-grey-darker
          "
        >
          Button with content
        </p>

        <Button
          className="
            col-span-12 tablet:col-span-4 desktop:col-span-3
            rounded-full 
            w-28 desktop:w-36 
            h-28 desktop:h-36
          "
          onClick={() => {
            console.log("Click!")
          }}
        >
          <RemoteImage
            imagePath="avatars/cat-in-hat.png"
            mobileImageSize="150px"
            className="rounded-full"
          />
        </Button>

        {/* Button with icons */}
        <p
          className="
          col-span-12 
          mt-10 mb-5
          font-bold text-xl
          text-grey-darker
          "
        >
          Button with icons
        </p>

        <Button
          type="secondary"
          keyline={true}
          icon={
            <FontAwesomeIcon
              icon={FlushedRegular}
              className="h-14 w-14 text-grey"
            />
          }
          iconHover={
            <FontAwesomeIcon
              icon={FlushedSolid}
              className="h-14 w-14 text-grey-dark"
            />
          }
          className="
            col-span-12 tablet:col-span-4 desktop:col-span-3
            rounded-full 
            w-28 desktop:w-36 
            h-28 desktop:h-36
          "
          onClick={() => {
            console.log("Click!")
          }}
        ></Button>

        <div className="col-span-12 my-20" />
      </GridLayout>
    </>
  )
}

export default Buttons
